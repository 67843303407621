@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,900&display=swap');


html {
    @apply scroll-smooth
}

body {
  @apply font-roboto text-lg text-body-color;
}

@layer base {
  a {
    @apply no-underline transition duration-300;
  }

  a:hover {
    @apply no-underline;
  }

  p {
    @apply pb-[1em];
  }

  h1 {
    @apply text-[38px] md:text-[52px] xl:text-8xl leading-[1];
  }

  h2 {
    @apply text-4xl xl:text-5xl;
  }

  h3 {
    @apply text-[32px] leading-[1.38em] md:text-4xl;
  }

  h4 {
    @apply text-2xl;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-roboto font-bold text-title-color mb-2.5;
  }

  ul,
  ol {
    @apply list-none m-0 p-0;
  }
}

.mb0 {
  @apply mb-0
}

.btn-light {
  @apply inline-block rounded-[32px] border-2 border-button-color bg-white px-5 py-0 text-center text-base font-roboto font-semibold 
    text-button-color h-12 leading-[44px] transition duration-500 hover:bg-button-color hover:text-white;
}

.btn-primary {
  @apply font-roboto font-semibold text-base text-white inline-block rounded-[32px] bg-button-color border-2 border-button-color px-5 py-0 relative
    text-center w-40 h-12 leading-[44px] transition duration-500 hover:border-[#6239F7] hover:bg-[#6239F7];
}

.btn-primary:after {
  @apply bg-[url('/images/ic-arrow-white.svg')] bg-no-repeat content-[""] absolute right-3 top-2/4 w-4 h-4 -translate-y-2/4
    transition-all duration-500;
}
/*
.btn-primary:hover::after {
  
}
*/
.view-all:after {
  @apply bg-[url('/images/ic-arrow-view-all.svg')] bg-no-repeat content-[""] absolute right-0 top-2/4 w-4 h-4 -translate-y-2/4
    transition-all duration-300;
}

.view-all:hover:after {
  @apply bg-[url('/images/ic-arrow-view-all-hover.svg')] bg-no-repeat;
}

.sticky-outer-wrapper .menu-sticky {
  transition-delay: -1ms;
}

.sticky-outer-wrapper.stickyHeaderOpen .menu-sticky {
  background-color: white;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
  transition-duration: 2ms;
  transition-property: background-color;
}


.sticky-outer-wrapper .menu-sticky.white-theme {
  @apply bg-white
}

.inner-menu {
  border-top: 1px solid #ccc;
  min-height: 66px;
}

.sticky-outer-wrapper {
  @apply !h-auto w-full
}

.sticky-outer-wrapper.stickyHeaderOpen {
  @apply w-full
}

.sticky-inner-wrapper {
  z-index: 9999;
  transform: inherit !important;
}

.menu-sticky ul li a {
  color: white;
}

.stickyHeaderOpen ul li a {
  @apply text-black  py-2.5;
}

.stickyHeaderOpen ul li.menu-item-signup a {
    @apply border-black my-3 py-1.5
}

.stickyHeaderOpen .menu-wrap .main-menu ul {
    @apply mt-4
}

.stickyHeaderOpen .menu-wrap .rightBlock ul {
    @apply mt-2.5
}


/* 
.stickyHeaderOpen .left-menu,
.stickyHeaderOpen .right-menu {
  margin-top: 0px;
}

.white-theme .left-menu,
.white-theme .right-menu {
  margin-top: 0px;
}
 */
.menu-sticky.white-theme ul li a {
  @apply text-black;
}

.menu-sticky.white-theme .mobile-menu-wrap ul li a {
  color: black;
  border-color: black;
}

.menu-sticky.white-theme .open-nav-btn .text-white {
  color: black;
}

.menu-sticky.white-theme .mobile-menu-wrap ul.mobileLanguageSwitcher li a {
  color: white;
}

.menu-sticky.white-theme .menu-item-language > a {
  @apply bg-[url('/images/ic-language-black.png')] bg-no-repeat bg-left pl-7;
}

.menu-sticky .menu-item-language ul li a {
  color: black;
}

.menu-sticky.white-theme .menu-item-signin > a {
  @apply bg-[url('/images/ic-user-black.svg')] bg-no-repeat bg-left pl-7;
}

.menu-sticky.white-theme .menu-wrap a.signup-for-free {
  border-color: black;
  color: black;
}

.stickyHeaderOpen .menu-wrap .main-menu .has-children {
  @apply bg-[url('/images/ic-arrow-faq-down.svg')] bg-no-repeat bg-right pr-5 transition-all duration-300;
}

.stickyHeaderOpen .menu-wrap .main-menu .has-children:hover {
  @apply bg-[url('/images/ic-arrow-faq-up.svg')] bg-no-repeat bg-right pr-5;
}

.stickyHeaderOpen .menu-sticky .menu-item-language > a {
  @apply bg-[url('/images/ic-language-black.svg')] bg-no-repeat bg-left pl-7;
}

.stickyHeaderOpen .menu-sticky .menu-item-signin > a {
  @apply bg-[url('/images/ic-user-black.svg')] bg-no-repeat bg-left pl-7;
}

.stickyHeaderOpen .menu-sticky .menu-wrap .has-children {
  @apply bg-[url('/images/ic-arrow-faq-down.svg')] bg-no-repeat bg-right;
}

.stickyHeaderOpen .menu-sticky .menu-wrap .has-children:hover {
  @apply bg-[url('/images/ic-arrow-faq-up.svg')] bg-no-repeat bg-right;
}

.menu-sticky .menu-wrap .has-children.menu-item-signup,
.menu-wrap .has-children.menu-item-signup {
  @apply bg-none pr-0 transition-all duration-300;
}

.menu-sticky .menu-wrap .has-children.menu-item-signup:hover,
.menu-wrap .has-children.menu-item-signup:hover {
  @apply bg-none pr-0;
}

.menu-wrap a.signup-for-free {
  color: black;
  border-color: black;
}

.menu-sticky .menu-wrap a.signup-for-free {
  border-color: white;
  color: white;
}

.stickyHeaderOpen .menu-sticky .menu-wrap a.signup-for-free {
  border-color: black;
  color: black;
}

.sticky-outer-wrapper .white-theme .logo-block a img,
.sticky-outer-wrapper .white-theme .mobile-menu-wrap a.logo-box img {
  display: none;
}

.sticky-outer-wrapper .white-theme .logo-block a,
.sticky-outer-wrapper .white-theme .mobile-menu-wrap a.logo-box {
  @apply bg-[url('/images/logo-black.svg')] bg-no-repeat bg-center w-[99px] h-[49px] block;
}

.stickyHeaderOpen .logo-block a img,
.stickyHeaderOpen .mobile-menu-wrap a.logo-box img {
  display: none;
}

.stickyHeaderOpen .mobile-menu-wrap a.logo-box {
  @apply bg-[url('/images/logo-black.svg')] bg-no-repeat bg-center w-[99px] h-[49px] block;
}

.stickyHeaderOpen .logo-block a {
    @apply bg-[url('/images/witopia-logo.svg')] bg-no-repeat bg-center w-[99px] h-9 block bg-[length:auto_36px]
}

.stickyHeaderOpen .menu-wrap .rightBlock ul {
    @apply mt-2.5
}

.stickyHeaderOpen .open-nav-btn svg {
  color: black;
}

.top-sticky {
  background: linear-gradient(90deg, #8F59FF 0.6%, #2E0387 99.99%);
  position: relative;
}

.top-sticky::before {
  background: url("/images/black-friday-bg.png") repeat left top;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.4;  
}

.promo-modal-wrap {
  @apply bg-[url('/images/bg-promo.png')] bg-no-repeat pl-[140px] py-[20px] lg:py-[48px] lg:min-h-[200px] relative font-Inter bg-[length:109px_100%] 
}

.promo-modal-content {
  @apply text-[#2F2F2F] text-[26px] font-bold pr-[48px]
}

.promo-modal-content p {
  @apply p-0 m-0 mb-[10px] leading-tight lg:text-[26px] text-[18px] font-bold font-Inter
}

.promo-modal-content p b {
  @apply text-[#FF7900] lg:text-[36px] text-[26px]
}

.promo-modal-wrap a {
  @apply outline-none border-none underline pr-[40px] text-[24px] font-bold relative
}

.promo-modal-wrap a::before {
  @apply content-[""] absolute right-0 w-[33px] h-[33px] bg-[url('/images/arrow-popup.svg')] bg-no-repeat bg-center
}

.promo-modal-wrap a:hover {
  @apply no-underline
}

.promo-modal-wrap a:focus
.promo-modal-wrap a:focus-visible {
  border: none;
  outline: none;
}

.promo-content p {
  @apply p-0 m-0 text-lg font-Inter
}

.promo-content a {
  @apply text-[#FF4265] underline text-lg font-Inter
}

.promo-content a:hover {
  @apply no-underline
}

#headlessui-portal-root {
  @apply relative z-[9999]
}

.stickyHeaderOpen .mobile-menu-wrap a.mob-signup {
  border-color: black;
  color: black;
}

.intro-hero{
  transition: padding 100ms;
}

#general-hero{
  transition: padding 100ms;
}

.intro-hero {
  background-image: linear-gradient(60deg, #220084 0, #de33ae);
  position: relative;
  width: 100%;
}

.intro-hero::after {
  position: absolute;
  content: "";
  bottom: 0px;
  width: 100%;
  background: url('/images/shape.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left bottom;
  height: 280px;    
}

.inner-banner {
  background: url('/images/inner-banner-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

.left-right-padding.inner-banner h1 {
  @apply lg:px-[148px]
}

.inner-banner .content-hero {
  max-width: 810px;
  margin: 0 auto;
}

.inner-banner .content-hero p {
  padding: 0px;
  margin: 0px;
  font-weight: 400;
  color: #fff;
  font-size: 1.5rem;
  line-height: 2rem;
}

.inner-banner .content-hero a {
  color: white;
}

.inner-banner.pricing-hero-section {
    background: none;
    background-color: #F7F5FE;
    min-height: 300px;
    @apply pb-14
}

.inner-banner.pricing-hero-section h1 {
    @apply mx-auto max-w-[850px] font-roboto md:text-5xl font-semibold leading-[1.16em] text-[#2C2271]
}

.inner-banner.pricing-hero-section .content-hero{
  @apply max-w-[860px]
}
.inner-banner.pricing-hero-section .content-hero p{
    @apply font-roboto font-normal md:text-xl text-black pb-4 mb-0
}

.inner-banner.pricing-hero-section .content-hero p:nth-last-child(1){
  @apply  pb-0
}

.inner-banner p:nth-last-child(1) {
    @apply p-0 m-0
}

.inner-menu {
    @apply border-b border-[#DBDBDB]
}

.blog-banner {
    @apply bg-gradient-to-r from-[#270785] to-[#D164EB]
}

.blog-banner .container {
    @apply md:flex items-center
}

.blog-banner h3 {
    @apply md:border-l md:border-white md:pl-7 md:ml-7 mb-0
}

/*
.blog-detail-banner .author-block li {
  line-height: 28px;
}

.blog-detail-banner .author-block li::after {
  @apply content-[""] absolute w-px h-2.5 bg-body-color -right-2 top-2.5;
}

.blog-detail-banner .author-block li:nth-last-child(1)::after {
  display: none;
}
*/

.subscribe-form input[type='email'] {
  @apply bg-white w-full border-2 border-white h-12 pl-5 pr-[120px] md:pr-[90px] lg:pr-[120px] rounded-3xl outline-none shadow-none font-roboto font-normal text-sm text-black;
}

.subscribe-form input::placeholder {
  @apply text-[#5F6368] text-sm
}

.subscribe-form input[type='email']:focus {
  @apply bg-white
}

.subscribe-form button {
  @apply bg-[#8264EB] rounded-3xl font-roboto font-semibold text-sm text-white w-28 md:w-20 lg:w-28 border-none absolute right-[2px] top-[2px] bottom-[2px] md:h-[44px]
}

.subscribe-form .inProgress {
  @apply bg-[#E0D9FF]
}

/*
    header search
*/

.f-w-search {
  z-index: 9999;
}

.header-search.open .search-form {
  @apply opacity-100 visible;
}

@keyframes fadein-right {
  0% {
    transform: translate(100px);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes fadein-top {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes opacity {
  0% {
    animation-delay: 1ms;	
    animation-duration: 1s;
    animation-timing-function: ease-in;   
    opacity: 0;
  }

  100% {
    opacity: 1;
    animation-delay: 1ms;	
    animation-duration: 1s;
    animation-timing-function: ease-in;  
  }
}

/*
.intro-text-box {
    display: block;
    animation-delay: 1ms;	
    animation-name: opacity;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;  
    opacity: 0;  
}

.intro-text-box h1 {
    animation-delay: 1ms;	
    animation-name: opacity;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;  
    opacity: 0; 
}

.intro-text-box > div {
    animation-delay: 1ms;	
    animation-name: opacity;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;  
    opacity: 0; 
}


.device-img {
  display: block;
  animation-delay: 1s;
  animation-name: opacity;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.device-img .fadein-right {
  animation-delay: 1s;
  animation-name: fadein-right;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  opacity: 0;
}

.device-img .fadein-top {
  animation-delay: 1s;
  animation-name: fadein-top;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  opacity: 0;
}

.pro-img-box .pro-img-item {
  display: none;
}
*/

.pro-img-box .pro-img-item {
  display: block;
  animation-delay: 1ms;
  animation-name: opacity;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.pro-img-box .pro-img-item img:nth-child(1) {
  animation-delay: 1ms;
  animation-name: fadein-right;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.pro-img-box .pro-img-item img:nth-child(2) {
  animation-delay: 1ms;
  animation-name: fadein-top;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.hover-arrow {
  position: relative;
}

.hover-arrow:after {
  transition: all 0.9s;
  content: '';
  background: url('/images/ic-arrow-white.svg') no-repeat left top;
  position: absolute;
  bottom: 0px;
  left: 20px;
  width: 30px;
  height: 30px;
}

.hover-arrow:hover:after {
  background: url('/images/ic-arrow-white-hover.svg') no-repeat left top;
}

.pro-item .btn-arrow {
  background: url('/images/ic-arrow-view-all-black.svg') no-repeat right center;
  padding-right: 20px;
}

.pro-item:hover .btn-arrow {
  background: url('/images/ic-arrow-view-all-hover.svg') no-repeat right center;
  color: #8264eb;
}

.pro-feature p {
  margin: 0px;
  padding: 0px;
}

.cat-list-style li::after {
  @apply content-[','] absolute -right-1;
}

.cat-list-style li:last-child::after {
  @apply content-[''];
}

.reviews-testimonial .tablinks {
  padding: 0px 10px;
}

.reviews-testimonial .tablinks.active {
  @apply text-light-purple relative;
}

.reviews-testimonial .tablinks.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0px;
  border: 1px solid #8264eb;
  width: 100%;
}

.review-item span::before {
  @apply w-[21px] h-[14px] content-[""] bg-[url('/images/ic-quote-left.svg')] bg-no-repeat absolute -left-2 top-1;
}

.review-item span::after {
  @apply w-[21px] h-[14px] content-[""] bg-[url('/images/ic-quote-right.svg')] bg-no-repeat absolute ml-1 mt-3;
}

.reviews-testimonial .review-logo img {
  width: auto;
  height: 100%;
}

.accordionTabWrap .tab-title {
  color: #895CF8;
}

.accordionTabWrap [aria-selected="true"] .tab-title {
  color: white;
}

/* faq css start */

.faq-page {
  padding: 70px 0px;
  margin: 0px !important;
}

.accordion-block .accordion-btn::after {
  @apply bg-[url('/images/ic-arrow-faq-down.svg')] bg-no-repeat content-[""] w-4 h-4 block
    absolute right-0 top-2/4 -translate-y-2/4 transition-all;
}

.accordion-block .accordion-btn[data-state='open']::after {
  @apply bg-[url('/images/ic-arrow-faq-up.svg')];
}

.accordion-block .accordion-body {
  transition: max-height 0.2s ease-out;
  border-bottom: 1px solid #d5d5d5;
}

/* faq css end */

.affiliate-content ul li {
  list-style: disc;
  color: black;
}

.prose :where(ul > li):not(:where([class~='not-prose'] *))::marker {
  color: #000;
}

.w-content.joincta .cta-wrap {
  max-width: 990px;
}

.about-content .content p {
  font-size: 20px;
  line-height: 28px;
}

.joincta {
  @apply xl:mb-24
}

.joincta p a {
  color: #fff;
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.joincta p a::after {
  position: absolute;
  content: '';
  left: 0px;
  right: 0px;
  height: 1px;
  bottom: 0px;
  border: 1px solid #fff;
}

.joincta p a:hover::after {
  border: none;
}

.w-content .button-wrap a {
  background: none;
  border: 2px solid #fff;
  color: white;
  font-weight: 500;
}

.w-content .button-wrap a:hover {
  background-color: white;
  color: #895CF8;
}

ul.mobile-sign-try .parent {
  margin-bottom: 20px;
}

ul.mobile-sign-try .toogle {
  color: #8264EB;
  margin-bottom: 10px;
  display: block;  
}

ul.mobile-sign-try .parent ul li {
  margin-bottom: 10px;
}

.details-content ul li,
.details-content ol li {
  @apply mb-4 text-lg
}
.details-content p {
  @apply prose-a:text-light-purple prose-a:underline text-lg text-[#4D4E56] leading-relaxed font-normal p-0 m-0 mb-8
}

.details-content img {
  @apply m-0 mb-6
}


.details-content h1,
.details-content h2,
.details-content h3,
.details-content h4,
.details-content h5,
.details-content h6 {
  @apply text-left m-0 mt-[46px] mb-[36px]
}

.details-content hr {
  @apply m-8
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  @apply text-[#4D4E56]
}

/* menu css start */

.menu-wrap .has-children:hover .sub-menu {
  @apply opacity-100 visible mt-0;
}

.menu-sticky .menu-wrap .has-children {
  @apply bg-[url('/images/ic-menu-dropdown.svg')] bg-no-repeat bg-right pr-5 transition-all duration-300;
}

.menu-sticky .menu-wrap .has-children:hover {
  @apply bg-[url('/images/ic-menu-dropdown-down.svg')] bg-no-repeat bg-right pr-5;
}

.menu-wrap .has-children {
  @apply bg-[url('/images/ic-arrow-faq-down.svg')] bg-no-repeat bg-right pr-5 transition-all duration-300;
}

.menu-sticky.white-theme .menu-wrap .has-children {
  @apply bg-[url('/images/ic-arrow-faq-down.svg')] bg-no-repeat bg-right pr-5 transition-all duration-300;
}

.menu-sticky.white-theme .menu-wrap .has-children:hover {
  @apply bg-[url('/images/ic-arrow-faq-up.svg')] bg-no-repeat bg-right pr-5;
}

.menu-sticky.white-theme .menu-wrap .has-children.menu-item-signup {
  background-image: none;
  padding: 0px;
}

.menu-wrap .has-children:hover {
  @apply bg-[url('/images/ic-arrow-faq-up.svg')] bg-no-repeat bg-right pr-5;
}

.menu-sticky .menu-item-language > a {
  @apply bg-[url('/images/ic-language-white.svg')] bg-no-repeat bg-left pl-7;
}

.menu-sticky .menu-item-signin > a {
  @apply bg-[url('/images/ic-user-white.svg')] bg-no-repeat bg-left pl-7 cursor-pointer;
}

.menu-item-language > a {
  @apply bg-[url('/images/ic-language-black.png')] bg-no-repeat bg-left pl-7;
}

.menu-item-signin > a {
  @apply bg-[url('/images/ic-sign-in-black.png')] bg-no-repeat bg-left pl-7;
}

.menu-wrap .sub-menu-big .all-menu-wrap a {
  @apply transition-all duration-300 bg-right text-black;
}

.menu-sticky .sub-menu-big .all-article a {
  color: #000;
}

.menu-sticky .sub-menu-big .all-article a:hover {
  @apply text-light-purple;
}

.menu-sticky .sub-menu-big .all-article .bottom-menu a {
  color: #8264EB;
}

.menu-wrap .sub-menu-big .all-menu-wrap a:hover .title-wrap .menu-title span {
  @apply bg-[url('/images/ic-arrow-view-all-hover.svg')] bg-no-repeat bg-right pr-[30px] text-[#8264EB];
}

.menu-wrap .sub-menu-big .bottom-menu a,
.nav-menu .bottom-menu a {
  @apply bg-[url('/images/ic-arrow-view-all.svg')] bg-no-repeat bg-right pr-8 transition-all duration-300;
  color: #8264EB;
}

.menu-wrap .sub-menu-big .bottom-menu a:hover,
.nav-menu .bottom-menu a:hover {
  @apply bg-[url('/images/ic-arrow-view-all-hover.svg')] bg-no-repeat;
}

.lan-sub-menu.active,
.mob-sub-menu.active,
.mob-signup-menu.active {
    @apply opacity-100 visible
}
/*
ul.icon-with-menu {

}
*/
ul.icon-with-menu li {
  width: 100%;
  margin-bottom: 10px;
}

ul.icon-with-menu li a {
  display: flex;
  align-items: center;
}

.all-article ul.grid li:last-child {
  margin-bottom: 0px;
}

ul.icon-with-menu li .item-icon-name {
  width: 32px;
  height: 32px;
}

ul.icon-with-menu li .title-wrap {
  width: -webkit-calc(100% - 32px); 
  width: -moz-calc(100% - 32px); 
  width: calc(100% - 32px);
}

header .nav-menu {
  @apply w-full -right-full invisible transition-all duration-500;
}

header .nav-menu.open {
  @apply visible right-0;
}

.menu-overlay.active {
  @apply visible opacity-100;
}

.nav-menu .has-child .sub-menu-toggle {
  @apply bg-[url('/images/ic-arrow-faq-down.svg')] bg-no-repeat bg-center;
}

.nav-menu .has-child.active .sub-menu-toggle {
  @apply rotate-180;
}

body.hidden-scrolling {
  overflow-y: hidden;
}

/* menu css end */

.prowrap .container {
  z-index: 99;
  position: relative;
}

/*
.prowrap::before {
    @apply content-[""] w-[15%] md:w-[15%] lg:w-[12%] h-52 absolute left-0 top-0 md:-top-[40px] lg:-top-[50px] bg-[#F7F5FE] skew-y-[45deg]
}
*/
.personalvpn-scroll .item-list .item:nth-child(4n + 0) .gray-bar {
  @apply hidden;
}

.globalvpn {
  @apply bg-[url('/images/bg-global-vpn-network.svg')];
}

/*
    home blog 
*/

.blog-article-list .blog-item:nth-child(1),
.blog-article-list .blog-item:nth-child(2) {
  @apply block;
}

/*
    about us page css
*/

.products-services .features-block::before {
  @apply absolute content-[""] bg-gradient-to-b from-[#220084] to-[#FA377B] w-1 h-full left-0 top-0 rounded-full;
}

.our-clients .client-block:nth-child(2) {
  @apply md:top-28 md:relative;
}

.address h3 {
  @apply bg-[url('/images/arrow-img.svg')] bg-no-repeat bg-top pt-16;
}

.choose-category-wrap .top-title-bar a {
  @apply bg-[url('/images/ic-arrow-view-all.svg')] bg-no-repeat bg-right pr-6 transition-all hover:bg-[95%_center];
}

.choose-category-wrap .articles-img {
  display: block;
}

.choose-category-wrap .articles-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ul.tab-heading-list li,
.choose-category-wrap nav ul li {
  outline: none;
}

.contact-item .link {
  @apply transition-all duration-300 bg-no-repeat bg-right pr-6;
}

.contact-item:hover .link {
  @apply bg-[url('/images/ic-arrow-red.svg')] bg-no-repeat bg-right;
}

.featuredPosts .author-list li,
.choose-category-wrap .author-list li,
.category-blog .author-list li {
  @apply relative;
}

.featuredPosts .author-list li::after,
.choose-category-wrap .author-list li::after,
.category-blog .author-list li::after {
  @apply content-[""] bg-body-color w-px h-2.5 absolute -right-2 top-1.5;
}

.featuredPosts .author-list li:nth-last-child(1)::after,
.choose-category-wrap .author-list li:nth-last-child(1)::after,
.category-blog .author-list li:nth-last-child(1):after {
  @apply hidden;
}

.category-blog .item-block::after {
  @apply content-[""] bg-[#DADADA] w-px h-full absolute -right-4 top-0 hidden sm:block;
}

.category-blog .item-block:nth-child(2n)::after {
  @apply sm:hidden md:block;
}

.category-blog .item-block:nth-child(3n + 0)::after {
  @apply md:hidden;
}

.right-blog-item .blog-item + .blog-item {
  @apply pt-10;
}

.right-blog-item .blog-item a img {
    @apply md:h-full
}

.breadcrumb ol li {
  @apply pr-3 flex
}

.breadcrumb ol li+ li {
  @apply bg-[url('/images/ic-breadcrumb-arrow.svg')] bg-no-repeat bg-left pl-[14px]
}

.blog-detail-banner .breadcrumb ol li {
  display: inherit;
}

.blog-detail-banner .breadcrumb ol li:nth-child(1) a {
  @apply w-2.5 h-3 block
}

.blog-detail-banner .breadcrumb ol li+ li {
  @apply bg-[url('/images/ic-breadcrumb-black.svg')] bg-no-repeat bg-[left_center] pl-[14px]
}

.blog-detail-banner .breadcrumb ol li span {
  @apply line-clamp-1
}

.slider-bar .slider-dot.active {
  @apply bg-[#252525] w-14 lg:w-52;
}

.arrows-block .prev {
  @apply bg-[url('/images/ic-prev-arrow.png')] bg-no-repeat bg-center;
}

.arrows-block .next {
  @apply bg-[url('/images/ic-next-arrow.png')] bg-no-repeat bg-center ml-2.5;
}

.download-all-assets {
  background-color: #8264EB;
  border-color: #8264EB;
}

.download-all-assets:hover {
  background-color: #7451EE !important;
  border-color: #7451EE !important;
}

a.download-all-assets span {
  @apply bg-[url('/images/ic-down.svg')] bg-no-repeat bg-[99%_50%] pr-8 inline-block
}

.logoShowcase .logo-box img {
  width: fit-content;
  max-height: 99px;
}

/* Product scrolling css */

.scroller {
  height: 100vh;
}

.item-scroll-wrap {
  height: 100vh;
}

.scroll-left-content {
  position: relative;
  overflow: hidden;
  height: 80vh;
}

.panel-text {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  opacity: 0;
}

.scroll-right-img {
  position: relative;
  overflow: hidden;
  height: 60vh;
}

.panel {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.panel._2 {
  z-index: 1;
}

.panel.one {
  z-index: auto;
}

.panel.two {
  z-index: auto;
}

.panel.three {
  z-index: auto;
}

.panel.four {
  z-index: auto;
}

.feature-content {
  @apply py-6
}

.bg-gray-light {
    @apply bg-[#F7F5FE] py-[64px]
}

.why-better-faq-section {
  @apply bg-[#F7F5FE] py-[64px] !mb-0
}

.bg-white-section ul,
.bg-gray-light ul {
    @apply list-disc list-outside ml-5
}

.bg-white-section ul li,
.bg-gray-light ul li {
    @apply mb-5
}

.accordion__input:checked ~ .accordion__content {
    display: block;
}

.bg-white-section {
    @apply bg-white md:pb-[100px] pb-10
}

.feature-content .right-side-image {
    @apply md:flex-row-reverse
}

.why-better-cta {
  @apply xl:mb-[60px]
}

.why-better-cta .cta-wrap {
    @apply bg-none max-w-[886px] px-0 pb-0
}

.why-better-cta .cta-wrap .prose {
    @apply text-black text-2xl
}

.why-better-cta .cta-wrap .prose p {
    @apply pb-0
}

.purple-cta.extra-padding{
  @apply md:mb-20
}

.cta-block p {
    @apply m-0 p-0
}

.intro-img-box .atropos-highlight {
  background-image: none;
}

.intro-img-box .atropos-shadow {
  background: transparent;
}

.pricing-cta {
  @apply bg-[#F7F5FE] m-0
}

.pricing-cta .cta-wrap {
  @apply bg-none sm:py-24
}

.pricing-cta .cta-wrap .prose {
  @apply text-black
}

.pricing-cta .cta-wrap .prose p {
  @apply m-0 p-0 text-2xl
}

.pricing-cta .cta-wrap .prose p:nth-child(3) {
  @apply mb-8
}

.subscription-block .top-block ul li:nth-child(1),
.subscription-block .top-block ol li:nth-child(1) {
    @apply bg-[url('/images/encrypt-email.svg')] bg-no-repeat bg-left lg:w-[30%] lg:pr-14
}

.subscription-block .top-block ul li:nth-child(2),
.subscription-block .top-block ol li:nth-child(2) {
    @apply bg-[url('/images/VPN-features.svg')] bg-no-repeat bg-left lg:w-[40%] lg:pr-14
}

.subscription-block .top-block ul li:nth-child(3),
.subscription-block .top-block ol li:nth-child(3) {
    @apply bg-[url('/images/30-day-guarantee.svg')] bg-no-repeat bg-left lg:w-[30%] lg:pr-5
}

.subscription-block .top-block ul,
.subscription-block .top-block ol {
  @apply flex flex-col lg:flex-row sm:items-center space-y-4 lg:space-y-0 max-w-[920px] mx-auto 
}

.subscription-block .top-block ul li,
.subscription-block .top-block ol li {
  @apply font-roboto font-normal text-black text-base relative pl-14 text-left min-h-[40px] flex items-center
}

.subscription-block .top-block ul li::before,
.subscription-block .top-block ol li::before {
    @apply lg:content-[""] bg-[#707070]/40 w-px h-full absolute right-9
}

.subscription-block .top-block ul li:nth-child(3)::before,
.subscription-block .top-block ol li:nth-child(3)::before {
    @apply hidden
}


.subscription-block .subscription-box ul,
.subscription-block .subscription-box ol {
  @apply mb-14 list-none m-0 p-0
}

.subscription-block .subscription-box ul li,
.subscription-block .subscription-box ol li {
  @apply relative pl-9 font-roboto font-normal text-lg mb-5 !mt-0
}

.subscription-block .subscription-box ul li::before,
.subscription-block .subscription-box ol li::before {
  @apply content-[""] bg-[url('/images/icon-tick-green.svg')] bg-no-repeat bg-center 
  w-[18px] h-[14px] absolute left-0 top-1.5
}

.subscription-block .subscription-box h6 {
  @apply content-[""] bg-[url('/images/icon-dollar.svg')] bg-no-repeat bg-left-top
  font-roboto font-bold text-sm text-[#007A4A] pl-8 m-0 min-h-[16px]
}

.subscription-block .subscription-box .subscription-content .bordard {
  @apply  font-roboto font-bold text-base sm:text-xl text-[#8264EB] border-[3px] border-[#8264EB] rounded-[27px] block text-center py-2.5 px-1.5 mb-5 hover:bg-[#8264EB] hover:text-white
}

.subscription-block .subscription-box .subscription-content .white {
  @apply  font-roboto font-bold text-base sm:text-xl text-[#8264EB] border-[3px] border-[#8264EB] rounded-[27px] block text-center py-2.5 px-1.5 mb-5 hover:bg-[#8264EB] hover:text-white
}

.subscription-block .subscription-box .subscription-content .filled {
  @apply bg-[#8264EB] font-roboto font-bold text-base sm:text-xl text-white border-[3px] border-[#8264EB] rounded-[27px] block text-center py-2.5 px-1.5 mb-5 hover:bg-white hover:text-[#8264EB]
}

.subscription-block .subscription-box .subscription-content .purple {
  @apply bg-[#8264EB] font-roboto font-bold text-base sm:text-xl text-white border-[3px] border-[#8264EB] rounded-[27px] block text-center py-2.5 px-1.5 mb-5 hover:bg-white hover:text-[#8264EB]
}

.subscription-block .details-content {
  @apply font-roboto font-medium text-lg relative p-0
}

.subscription-block .details-content p {
  @apply m-0 p-0
}

.subscription-block .subscription-box .footer-content {
  @apply w-full font-roboto font-normal text-sm text-black
}

.subscription-block .subscription-box .footer-content p {
  @apply m-0 p-0
}

.subscription-block .subscription-box .best-choice-top {
  @apply hidden
}

.subscription-block .subscription-box.best-choice {
  @apply bg-[#2C2271] mt-14 sm:mt-0
}

.subscription-block .subscription-box.best-choice .best-choice-top {
  @apply block
}

.securemyemail-vpn.subscription-block .subscription-container {
  @apply flex flex-wrap sm:flex-nowrap sm:space-x-6 justify-center
}

.securemyemail-vpn.subscription-block .subscription-container .subscription-box {
  @apply max-w-[415px] w-full shadow-[0_10px_20px_0_rgba(0,0,0,0.16)] rounded-[20px] py-10 px-8 relative
}

.securemyemail-vpn.subscription-block .subscription-container .subscription-box.best-choice {
  @apply max-w-[415px] w-full shadow-[0_10px_20px_0_rgba(0,0,0,0.16)] rounded-[20px] py-10 px-8 relative
}

.securemyemail-vpn.subscription-block .subscription-box.best-choice .best-choice-top {
  @apply bg-[#DE2C93] font-roboto font-bold text-base text-white text-center px-2 p-1 pb-10 absolute left-0 -top-8 w-full rounded-[20px_20px_0_0] -z-10
}

.securemyemail-vpn.subscription-block .subscription-box.best-choice ul li,
.securemyemail-vpn.subscription-block .subscription-box.best-choice ol li {
  @apply text-white
}

.securemyemail-vpn.subscription-block .subscription-box.best-choice .subscription-btn {
  @apply bg-[#8264EB] font-roboto font-bold text-xl text-white border-[3px] border-[#8264EB] rounded-[27px] 
  block text-center py-2.5 px-1.5 mb-5 hover:bg-white hover:text-[#8264EB]
}

.secure-email-only.subscription-block .subscription-container {
  @apply flex flex-wrap lg:flex-nowrap space-y-6 sm:space-y-0 lg:space-x-6 justify-center
}

.vpn-only.subscription-block .subscription-container {
  @apply flex flex-wrap lg:flex-nowrap space-y-6 sm:space-y-0 lg:space-x-6 justify-center
}

.vpn-only.subscription-block .subscription-box.best-choice,
.secure-email-only.subscription-block .subscription-box.best-choice {
  @apply sm:w-[48%] lg:w-1/3 xl:w-full max-w-[370px] sm:mx-[1%] lg:mx-0 bg-[#DE2C93] rounded-[20px] relative px-1 pb-1 lg:!-mt-10 sm:!mt-5 shadow-[0_10px_20px_0_rgba(0,0,0,0.16)] pt-0
}

.vpn-only.subscription-block .subscription-box.best-choice .best-choice-top,
.secure-email-only.subscription-block .subscription-box.best-choice .best-choice-top {
  @apply font-roboto font-bold text-base text-white text-center py-2 px-2.5 w-full mb-0 bg-none
}

.vpn-only.subscription-block .subscription-box.best-choice .subscription-content,
.secure-email-only.subscription-block .subscription-box.best-choice .subscription-content {
  @apply bg-white h-[calc(100%-40px)] rounded-[20px] p-8 relative 
}

.vpn-only.subscription-block .subscription-box.best-choice .subscription-content .show-save-badge,
.secure-email-only.subscription-block .subscription-box.best-choice .subscription-content .show-save-badge {
  @apply absolute top-[6px] right-[8px] bg-[#FCECEC] rounded-[22px] font-roboto font-semibold text-[13px] 
  text-[#AD0066] py-0.5 px-3
}

.vpn-only.subscription-block .subscription-box.best-choice .subscription-content h4,
.secure-email-only.subscription-block .subscription-box.best-choice .subscription-content h4 {
  @apply font-roboto font-normal text-xl text-black mb-5
}

.vpn-only.subscription-block .subscription-box.best-choice .subscription-content h3,
.secure-email-only.subscription-block .subscription-box.best-choice .subscription-content h3 {
  @apply font-roboto font-bold text-black text-[40px] leading-[28px] border-b border-[#DEDEE0] pb-8 mb-10
}

.subscription-block .subscription-box.best-choice h3 {
  @apply font-roboto font-bold text-white text-[40px] leading-[28px] border-b border-white/20 pb-8 mb-10
}

.subscription-block .subscription-box.best-choice h4 {
  @apply font-roboto font-normal text-xl text-white mb-5
}

.subscription-block .subscription-box.best-choice h6 {
  @apply bg-[url('/images/icon-dollar.svg')] bg-no-repeat bg-left-top text-[#007A4A]
}

.securemyemail-vpn.subscription-block .subscription-box.best-choice h6 {
  @apply bg-[url('/images/icon-dollar-white.svg')] text-white
}

.secure-email-only {
  @apply bg-[#F7F5FE]
}

.secure-email-only .top-block ul,
.vpn-only .top-block ul {
    @apply flex flex-col items-center space-y-1
}

.secure-email-only .top-block ul li,
.vpn-only .top-block ul li {
    @apply font-roboto font-normal text-lg text-black
}

.secure-email-only .top-block ul li a,
.vpn-only .top-block ul li a {
    @apply text-[#008FF9] underline
}

.secure-email-only .top-block ul li a:hover,
.vpn-only .top-block ul li a:hover {
    @apply no-underline
}

/*
.vpn-only .subscription-container,
.secure-email-only .subscription-container {
  @apply flex flex-wrap lg:flex-nowrap space-y-6 sm:space-y-0 lg:space-x-6 justify-center
}
*/
.secure-email-only .subscription-box {
  @apply bg-white sm:w-[48%] lg:w-1/3 xl:w-full max-w-[370px] sm:mx-[1%] lg:mx-0 shadow-[0_10px_20px_0_rgba(0,0,0,0.16)] rounded-[20px] py-8 px-[30px]
}

.secure-email-only .subscription-box:nth-child(2) {
  @apply bg-white sm:w-[48%] lg:w-1/3 xl:w-full max-w-[370px] sm:mx-[1%] lg:mx-0 shadow-[0_10px_20px_0_rgba(0,0,0,0.16)] rounded-[20px] p-8
}

.secure-email-only .subscription-box.best-choice {
  @apply sm:w-[48%] lg:w-1/3 xl:w-full max-w-[370px] sm:mx-[1%] lg:mx-0 bg-[#DE2C93] rounded-[20px] relative pt-10 px-1 pb-1 lg:!-mt-10 sm:!mt-5 shadow-[0_10px_20px_0_rgba(0,0,0,0.16)]
}

.color-title.subscription-box h4 {
  font-size: 40px;
  background: linear-gradient(to right, #8264EB 0%, #D833AE 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.secure-email-only .color-title.subscription-box h4 {
  @apply font-roboto font-bold text-[40px] leading-[1.1] border-b border-[#DEDEE0] pb-6 mb-10
}

.secure-email-only .subscription-box ul,
.vpn-only .subscription-box ul,
.cloakBox-tab .subscription-box ul {
    margin-bottom: 40px;
}

.secure-email-only .subscription-box ul li,
.vpn-only .subscription-box ul li,
.cloakBox-tab .subscription-box ul li {
    @apply relative pl-9 font-roboto font-normal text-lg mb-5
}

.secure-email-only .subscription-box ul li::before,
.vpn-only .subscription-box ul li::before,
.cloakBox-tab .subscription-box ul li::before {
    @apply content-[""] bg-[url('/images/icon-tick-green.svg')] bg-no-repeat bg-center 
    w-[18px] h-[14px] absolute left-0 top-1.5
}

.secure-email-only .subscription-box h6,
.vpn-only .subscription-box h6 {
    @apply content-[""] bg-[url('/images/icon-dollar.svg')] bg-no-repeat bg-left-top bg-[length:16px_16px]
    font-roboto font-bold text-xs text-[#007A4A] pl-6 m-0
}

/*
.secure-email-only .subscription-box:nth-child(2) h6,
.secure-email-only .subscription-box:nth-child(3) h6 {
    @apply bg-none
}
*/

.cloakbox-routers {
  @apply bg-[#F7F5FE]
}

.cloakbox-routers .top-block p {
    @apply pb-1
}

.cloakbox-routers.subscription-block .top-block {
  @apply text-center font-roboto font-normal text-xl mb-16 max-w-[880px] mx-auto
}

.cloakbox-routers.subscription-block .top-block .details-content {
  @apply text-center font-roboto font-normal text-xl p-0
}

.cloakbox-routers.subscription-block .top-block .details-content p {
  @apply text-center font-roboto font-normal text-xl pb-1
}

.cloakBox-tab .tab-wrapper .tabs-heading {
  @apply w-full block !mt-0 border-none
}

.cloakBox-tab .tab-wrapper .tabs-heading .tab-heading-list {
  @apply flex justify-center items-center !mb-16 !mt-0 border-none
}

.cloakBox-tab .tab-wrapper .tabs-heading .tab-heading-list li {
  @apply w-1/2 font-roboto font-bold text-3xl text-black border-b-[3px] border-[#D9D9D9] 
  pb-2.5 transition-all hover:text-[#6239F7] hover:border-b-[#6239F7] text-center
}

.cloakBox-tab .tab-wrapper .tabs-heading .tab-heading-list li.active {
    @apply w-1/2 font-roboto font-bold text-3xl border-b-[3px] border-[#D9D9D9] text-center !bg-transparent
    pb-2.5 transition-all hover:text-[#6239F7] hover:border-b-[#6239F7] text-[#6239F7] border-b-[#6239F7]
}

.cloakBox-tab .tab-wrapper .tabs-heading .tab-heading-list li .tab-title {
  @apply p-0 m-0
}

.cloakBox-tab .tab-wrapper .tabs-panel .tab-content .tab-intro-content {
  @apply max-w-[660px] text-center font-roboto font-normal text-lg text-black mx-auto mb-10
}

.cloakBox-tab .tab-wrapper .tabs-panel {
  @apply p-0
}

.cloakBox-tab .tab-wrapper .tabs-panel .tab-content .tab-intro-content p {
  @apply m-0 p-0 text-center font-roboto font-normal text-lg text-black
}

.cloakBox-tab .tab-wrapper .tabs-panel .tab-content .subscription-box {
  @apply bg-white max-w-[415px] w-full shadow-[0_10px_20px_0_rgba(0,0,0,0.16)] rounded-[20px] pt-6 pb-7 px-5 sm:px-8 text-left
}

.cloakBox-tab .tab-wrapper .tabs-panel .tab-content .subscription-box .renew-text-badge {
  @apply relative top-0 right-0 bg-[#FCECEC] font-roboto font-normal text-[13px] text-[#AD0066] py-0.5 px-4 rounded-[22px] inline-block mb-3
}

.cloakBox-tab .subscription-box .bottom-box p {
    @apply pb-1
}

.thank-you-content h1 {
  @apply font-roboto font-bold xl:text-6xl text-white xl:leading-[1.16em] mb-0
}

.thank-you-content h2 {
  @apply font-roboto font-bold xl:text-6xl text-white xl:leading-[1.16em] !m-0
}

.thank-you-content h3 {
  @apply font-roboto font-semibold text-white mb-0 text-2xl md:text-3xl leading-[1.06em]
}

.thank-you-content .details-content p {
  @apply font-roboto font-normal text-lg text-white leading-[1.1em]
}

.feature-content.affiliate-program .container .right-side-image {
  @apply lg:px-[100px]
}

.feature-content.affiliate-program .container h2 {
  @apply mb-0 text-left font-semibold font-roboto
}

.feature-content.affiliate-program .container .right-side-image .block-content {
  @apply text-lg
}

.feature-content.affiliate-program .container .right-side-image .block-content h2 {
  @apply mb-[26px] text-[36px]
}

.affiliate-program {
  @apply py-10 md:py-16
}

.affiliate-program a {
    @apply text-[#8264EB] underline hover:no-underline
}

.why-wiTopia-affiliate .container {
  @apply lg:px-[100px]
}

.why-wiTopia-affiliate ul {
    @apply flex flex-wrap
}
.why-wiTopia-affiliate ul li {
    @apply w-full md:w-[50%] flex text-left md:pr-24 mb-7 items-center font-roboto text-[18px]
}

.why-wiTopia-affiliate ul li img {
    @apply mr-7
}

.joincta a {
    @apply underline hover:no-underline
}

.how-much .item-list:nth-child(odd) {
    @apply bg-[#EDE8FE]
}

.service-item .logo-header:first-child {
  @apply border-none pt-0 mt-0
}

.service-item .logo-header:first-child img {
  @apply w-auto
}

.service-item .logo-header {
  @apply border-t-2 pt-4 mt-4
}

.service-item .logo-header img {
  @apply w-[104px]
}

.promo-content p {
  @apply p-0 m-0 text-lg font-Inter
}

.promo-content a {
  @apply text-[#FF4265] underline text-lg font-Inter
}

.promo-content a:hover {
  @apply no-underline
}

#headlessui-portal-root {
  @apply relative z-[9999]
}

.w-content.affiliate-cta .cta-wrap {
  @apply max-w-[748px]
}

.w-content.affiliate-cta .cta-wrap h2 {
  @apply mb-[20px] leading-tight text-[36px]
}


.item-list-header .item-header-logo {
  @apply h-[50px]
}

.article-outline-block {
  @apply mb-5 bg-[#f3f3f3] p-5 pr-2 rounded-md
}

.article-outline-block ol {
  @apply list-outside font-normal text-base max-h-[320px] overflow-y-scroll
}

.article-outline-block ol li {
  @apply relative
}

.article-outline-block ol li.active::after,
.article-outline-block ol li:hover::after {
	@apply bg-light-purple content-[""] w-[3px] min-h-[36px] h-[110%] block absolute -left-8 top-1/2 -translate-y-1/2;
}

.article-outline-block ol li:hover,
.article-outline-block ol li.active,
.article-outline-block ol li.active a {
  @apply text-light-purple
}
/* *,*:before,*:after {box-sizing:inherit;margin:0; padding:0; border:0 none; position: relative;}*/

.choose-category-wrap.post-details .top-title-bar {
  border-top:  1px solid #000;
}

.sticky-box {
  background: rgb(93,6,187);
  background: -moz-linear-gradient(0deg, rgba(93,6,187,1) 0%, rgba(69,6,162,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(93,6,187,1) 0%, rgba(69,6,162,1) 100%);
  background: linear-gradient(0deg, rgba(93,6,187,1) 0%, rgba(69,6,162,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5d06bb",endColorstr="#4506a2",GradientType=1);
  padding: 48px 24px;
  border-radius: 12px;
}

.sticky-box .gradient-title  {
  background: rgb(0,197,255);
  background: -moz-linear-gradient(90deg, rgba(0,197,255,1) 0%, rgba(197,150,255,1) 60%, rgba(255,62,198,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(0,197,255,1) 0%, rgba(197,150,255,1) 60%, rgba(255,62,198,1) 100%);
  background: linear-gradient(90deg, rgba(0,197,255,1) 0%, rgba(197,150,255,1) 60%, rgba(255,62,198,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00c5ff",endColorstr="#ff3ec6",GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sticky-box .btn-now,
.sticky-box-full .btn-now {
  background: rgb(191,183,255);
  background: -moz-linear-gradient(0deg, rgba(191,183,255,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(191,183,255,1) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(0deg, rgba(191,183,255,1) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bfb7ff",endColorstr="#ffffff",GradientType=1); 
  color: #080808;
  font-size: 18px;
  line-height: 48px;
  font-weight: 700;
  min-width: 168px;
  height: 48px;
  display: inline-block;
  border-radius: 24px;
  text-align: center;
  margin: 0px auto;
  text-decoration: none;
}

.sticky-box .btn-now:hover,
.sticky-box-full .btn-now:hover {
  background: rgb(255,255,255);
  background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(191,183,255,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(191,183,255,1) 100%);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(191,183,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#bfb7ff",GradientType=1); 
}

.sticky-box-full {
  background: rgb(93,6,187);
  background: -moz-linear-gradient(0deg, rgba(93,6,187,1) 0%, rgba(69,6,162,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(93,6,187,1) 0%, rgba(69,6,162,1) 100%);
  background: linear-gradient(0deg, rgba(93,6,187,1) 0%, rgba(69,6,162,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5d06bb",endColorstr="#4506a2",GradientType=1);
  padding: 32px 24px;
  border-radius: 12px;
}

.sticky-box-full .gradient-title span {
  background: rgb(0,197,255);
  background: -moz-linear-gradient(90deg, rgba(0,197,255,1) 0%, rgba(197,150,255,1) 60%, rgba(255,62,198,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(0,197,255,1) 0%, rgba(197,150,255,1) 60%, rgba(255,62,198,1) 100%);
  background: linear-gradient(90deg, rgba(0,197,255,1) 0%, rgba(197,150,255,1) 60%, rgba(255,62,198,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00c5ff",endColorstr="#ff3ec6",GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  @apply font-roboto;
}

.sticky-box-full ul {
  @apply flex flex-wrap justify-center p-0 gap-5
}

.sticky-box-full ul li {
  @apply md:max-w-[33%] m-0 p-0
}

.grid .sticky-box-full ul {
  @apply block w-full max-w-[70%] mx-auto
}

.grid .sticky-box-full ul li {
  @apply w-full max-w-full mb-5
}

.prose-xl :where(img):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1em;
}

.brand-row {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.brand-row .brand-item {
  cursor: pointer;
  border-radius: 50%;
  margin-right: -1.25rem;
  transition: margin .2s;
  position: relative;
}

.brand-row .brand-item:hover {
  margin-right: -1rem;
  position: relative;
  z-index: 100;
}

.brand-row .brand-item .tooltip {
  @apply bg-white text-black shadow-lg -top-12 transition-all absolute z-10 px-3 py-4 rounded-lg invisible 
}

.brand-row .brand-item:hover .tooltip {
  @apply visible
}

.bounce-in {
  animation: bounce-in 2s ease infinite;
}

.brand-row .brand-item:nth-child(1) .tooltip {
  left: -40px;
  top: -56px;
  rotate: 7deg;
  z-index: 50;
}

.brand-row .brand-item:nth-child(2) .tooltip {
  min-width: 100px;
  left: -50px;
  rotate: -4deg;
  top: -66px;
  z-index: 100;
}

.brand-row .brand-item:nth-child(3) .tooltip {
  min-width: 100px;
  left: -50px;
  rotate: 4deg;
  top: -54px;
  z-index: 150;
}

.brand-row .brand-item:nth-child(4) .tooltip {
  min-width: 100px;
  left: -50px;
  rotate: 0deg;
  top: -56px;
  z-index: 200;
}

.brand-row .brand-item:nth-child(5) .tooltip {
  min-width: 100px;
  left: -26px;
  rotate: 14deg;
  top: -54px;
  z-index: 250;
}

@keyframes bounce-in {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% { transform: scale(.9); }
  100% { transform: scale(1); }
}

/*---------- media css start ----------*/

@media (max-width: 360px) {

}

@media only screen and (max-width: 400px) {
  .promo-modal-content p {
    font-size: 16px;
  }
  .promo-modal-content p b {
    font-size: 20px;
  }
  .promo-modal-wrap {
    padding-left: 114px;
  }
  .promo-modal-content {
    padding-right: 10px;
  }
  .affiliate-program-section .affiliate-item .button-wrap a {
    margin-bottom: 20px;
    display: block;
  }
  .affiliate-program-section .affiliate-item .button-wrap .arrow-btn {
    padding: 0px;
  }
  .intro-hero::after {
    height: 114px;
  } 
}

@media only screen and (max-width: 450px) {
  .anchorblock {
    @apply my-10
  }
  .subscribe-form button.subscribe-btn {
    @apply bg-[url('/images/ic-arrow-subscribe.svg')] bg-no-repeat bg-left-top bg-inherit w-[24px] h-[24px] text-opacity-0 mt-[10px] mr-[10px]
  }
  iframe {
    width: 100% !important;
  }
  div.device-img img.hero-vpn-img {
    right: -34%;
    top: -2%;
  }
  .intro-text-box {
    margin-bottom: 20px;
  } 
  .btn-wrap .btn-light,
  .btn-wrap .btn-primary {
    width: 232px;
  }  
}

@media (min-width: 451px) and (max-width: 639.99px) {
  div.device-img img.hero-vpn-img {
    right: -18.99%;
    top: -2%;
  }
  .btn-wrap .btn-light,
  .btn-wrap .btn-primary {
    width: 252px;
  }  
}

@media only screen and (min-width: 640px) {
  .pro-feature ul li:nth-child(2) {
    @apply border-0;
  }
  .pro-feature ul li:nth-child(3) {
    @apply pl-0;
  } 
}

@media screen and (min-width: 700px) { 
  .animationWrap {
    display: flex;
    flex-wrap: wrap;
  }
  .animationWrap figure {
    background: #fff;
    width: calc(50% + 1px);
    height: 100vh;
    margin: 0 auto 10vh 0;
    position: sticky;
    top: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .animationWrap .text-box {
    background: #fff;
    width: calc(50% + 1px);
    height: 100vh;
    margin: 0 0 10vh auto;
    position: sticky;
    top: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .animationWrap figure:nth-of-type(1),
  .animationWrap .text-box:nth-of-type(1) {
    margin: 0 0 10vh 0;
    width: 50%;
  }

  .animationWrap figure:nth-of-type(3) {
    margin: 0 0 10vh auto;
  }

  .animationWrap .text-box:nth-of-type(3) {
    margin: 0 0 10vh 0;
    width: 50%;
  }

  .animationWrap figure:nth-of-type(2n) {
    margin: 0 0 10vh auto;
  }
  .animationWrap .text-box:nth-of-type(2n) {
    margin: 0 auto 10vh 0;
  }
  .animationWrap figure:last-of-type,
  .animationWrap .text-box:last-of-type {
    margin-bottom: 0;
  }

  .animationWrap .text-box > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 1rem;
  }

  /* SecureMyEmail section animation */

  .SmeAnimationWrap {
    display: flex;
    flex-wrap: wrap;
  }
  .SmeAnimationWrap figure {
    background: #fff;
    width: calc(50% + 1px);
    height: 100vh;
    margin: 0 auto 10vh 0;
    position: sticky;
    top: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .SmeAnimationWrap .text-box {
    background: #fff;
    width: calc(50% + 1px);
    height: 100vh;
    margin: 0 0 10vh auto;
    position: sticky;
    top: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .SmeAnimationWrap figure:nth-of-type(1),
  .SmeAnimationWrap .text-box:nth-of-type(1) {
    margin: 0 0 10vh 0;
    width: 50%;
  }

  .SmeAnimationWrap figure:nth-of-type(2n) {
    margin: 0 auto 10vh 0;
  }
  .SmeAnimationWrap .text-box:nth-of-type(2n) {
    margin: 0 0 10vh auto;
  }
  .SmeAnimationWrap figure:last-of-type,
  .SmeAnimationWrap .text-box:last-of-type {
    margin-bottom: 0;
  }

  .SmeAnimationWrap .text-box .text-box-inner {
    padding: 0px 40px;
  }
}

@media only screen and (max-width: 768px) { 
  .anchorblock {
    @apply mb-10
  }

  .anchorblock .tooltip {
    @apply hidden
  }
  .stickyHeaderOpen ul li a {
    padding: 0px;
  }
  .personalvpn-scroll .pb-28.text-center {
    padding-bottom: 40px;
  }
  .personalvpn-scroll .flex.w-full.pb-24 {
    padding-bottom: 40px;
  }  
  ul.icon-list {
    top: 30%;
    left: 10px;
  }
  .intro-hero::after {
    height: 112px;
  }  
  .blog-article-list .blog-item .cat-list-style li a {
    padding: 20px;
    display: block;
  }
  .cat-list-style li::after {
    display: none;
  }
/*
  ul.author-list li a.text-body-color {
    padding: 20px;
    display: inline-block;
  }
  */
}

@media screen and (max-width: 768px) {
    .inner-banner h1 br {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
  /* .my-atropos {
    display: none !important;
  }    */
  div.device-img {
    padding-top: 40px;
  }
  div.device-img .hero-vpn-img {
    right: -16%;
    top: -10%;
  }
  ul.icon-list {
    top: 25%;
  } 
  .intro-hero::after {
    height: 114px;
  }
}

@media only screen and (width: 820px) {
  .anchorblock {
    margin-bottom: 60px;
  }
  .intro-hero::after {
    height: 114px;
  }
}

@media only screen and (min-width: 1024px) { 
  .stickyHeaderOpen ul li a {
    @apply text-black;
  }
  .pro-feature ul li:nth-child(1) {
    @apply w-1/5 pl-0;
  }
  .pro-feature ul li:nth-child(2) {
    @apply w-[30%] border-r;
  }
  .pro-feature ul li {
    @apply pl-7;
  }
  .pro-feature ul li:nth-child(3) {
    @apply pl-7;
  }
  .intro-hero::after {
    height: 174px;    
  }
}

@media only screen and (max-width: 1023px) {
  .stickyHeaderOpen nav ul li a {
    @apply text-white;
  }
  .menu-sticky.white-theme ul li a {
    color: white;
  }

  .menu-item .all-menu-wrap .bottom-menu a {
    @apply bg-[url('/images/ic-arrow-view-all.svg')] bg-no-repeat bg-right pr-8 transition-all duration-300;
    color: #8264EB;
  }
  
  .menu-item .all-menu-wrap .bottom-menu a:hover {
    @apply bg-[url('/images/ic-arrow-view-all-hover.svg')] bg-no-repeat;
  } 
  
  .w-content.joincta .button-wrap {
    display: block;
  }

  .w-content.joincta .button-wrap a {
    margin-bottom: 20px;
  }

}

@media only screen and (max-width: 1023px) {
  .device-img .hero-vpn-img {
    right: -10%;
  } 
  .btn-wrap .btn-light,
  .btn-wrap .btn-primary {
    min-width: 250px;
  }
}

@media (min-width: 1024px) and (max-width: 1169.99px) {
  .icon-list-wrap .device-img .hero-vpn-img {
    height: 400px;
    right: -112px;
    top: -100px;
  }   
  .anchorblock {
    margin-top: -76px;
  }
}

@media (min-width: 1200px) {
  .blog-details .container {
    @apply max-w-[995px] mx-auto;
  }
  .blog-details .contentWrap {
    @apply w-[995px] justify-between;
  }
  .blog-details .contentWrap .contentCol {
    @apply w-[660px] pr-0;
  }
  .blog-details .contentWrap .rightCol {
    @apply w-[278px] pl-0;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    @apply px-0;
  }

  .have-top-sticky.intro-hero {
    min-height: 852px;
  } 
  
  .intro-hero {
    min-height: 780px;
  }  
  
  .intro-hero::after {
    height: 198px;    
  } 
  .sticky-outer-wrapper.stickyHeaderOpen .sticky-inner-wrapper .menu-sticky {
    padding: 6px 0px;
}
}

@media (min-width: 1169.99px) and (max-width: 1300px) {
  .have-top-sticky.intro-hero {
    min-height: 830px;
  } 
  .intro-hero {
    min-height: 768px;
  }  
  .intro-hero::after {
    height: 184px;
  }
}

@media (min-width: 1380px) and (max-width: 1600px) {
  .intro-hero::after {
    height: 221px;
  }
}

@media only screen and (max-width: 1440px) {
  ul.icon-list li {
    width: 36px;
  } 
}

@media (min-width: 1170px) and (max-width: 1535.99px) {
  .icon-list-wrap .device-img .hero-vpn-img {
    height: 440px;
    top: -100px;
    right: -104px;
  }
}

@media only screen and (min-width: 1536px) {
  .menu-item-signin .sub-menu {
    right: inherit;
  }
  .sticky-outer-wrapper.stickyHeaderOpen .sticky-inner-wrapper .menu-sticky {
    padding: 6px 0px;
  }
  .intro-hero {
    min-height: 900px;
  }
  .intro-hero.have-top-sticky {
    min-height: 968px;
  }
  .intro-hero .device-img {
    @apply w-[714px];
  }
  .pro-feature ul li,
  .pro-feature ul li:nth-child(1),
  .pro-feature ul li:nth-child(2) {
    @apply w-auto;
  }
  .icon-list-wrap .icon-list {
    @apply top-[38px] left-[0px];
  }
  .intro-img-box {
    width: 760px;
  }
  .intro-hero::after {
    height: 280px;    
  }
  .device-img .hero-vpn-img {
    width: 410px;
    height: 579px;
    top: -130px;
    right: -74px;
  }  
}

@media screen and (min-width: 1600px) {
  .blog-details .container {
    @apply max-w-[995px] mx-auto;
  }
  .blog-details .contentWrap {
    @apply w-[995px] justify-between;
  }
  .blog-details .contentWrap .contentCol {
    @apply w-[660px] pr-0;
  }
  .blog-details .contentWrap .rightCol {
    @apply w-[278px] pl-0;
  }
}

@media only screen and (min-width: 1940px) {
  .have-top-sticky.intro-hero {
    min-height: 1024px;
  }
  .intro-hero {
    min-height: 962px;
  }
  section.intro-hero::after {
    height: 420px;    
  }
}